import { render, staticRenderFns } from "./vip-tab-cy.vue?vue&type=template&id=11e3fe82&scoped=true&"
import script from "./vip-tab-cy.vue?vue&type=script&lang=js&"
export * from "./vip-tab-cy.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11e3fe82",
  null
  
)

export default component.exports
<template>
	<van-grid column-num="2">
		<van-grid-item :dot="subject.categoryId === '122'" @click="$emit('click', ['categoryId', '122'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/qy_03.png" alt="驾驶员">
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '123'" @click="$emit('click', ['categoryId', '123'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/qy_05.png" alt="客运">
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '124'" @click="$emit('click', ['categoryId', '124'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/qy_10.png" alt="货运">
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '125'" @click="$emit('click', ['categoryId', '125'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/qy_12.png" alt="危险品">
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '126'" @click="$emit('click', ['categoryId', '126'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/qy_15.png" alt="管理人员">
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '127'" @click="$emit('click', ['categoryId', '127'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/qy_17.png" alt="两类人员">
			</template>
		</van-grid-item>
	</van-grid>
</template>

<script>
export default {
    props: ['subject'],
};
</script>

<style scoped lang="less">

</style>

<template>
	<van-grid column-num="2">
		<van-grid-item :dot="subject.categoryId === '116'" @click="$emit('click', ['categoryId', '116'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/jxjy_05.png" alt="客运">
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '117'" @click="$emit('click', ['categoryId', '117'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/jxjy_03.png" alt="货运">
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '118'" @click="$emit('click', ['categoryId', '118'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/jxjy_09.png" alt="出租车">
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '119'" @click="$emit('click', ['categoryId', '119'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/jxjy_10.png" alt="教练员">
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '120'" @click="$emit('click', ['categoryId', '120'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/jxjy_14.png" alt="危险品">
			</template>
		</van-grid-item>
	</van-grid>
</template>

<script>
export default {
    props: ['subject'],
};
</script>

<style scoped lang="less">

</style>

<template>
	<van-grid column-num="2">
		<van-grid-item :dot="subject.categoryId === '109'" @click="$emit('click', ['categoryId', '109'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/cy_05.png" alt="客运">
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '110'" @click="$emit('click', ['categoryId', '110'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/cy_03.png" alt="货运">
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '111'" @click="$emit('click', ['categoryId', '111'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/cy_09.png" alt="出租车">
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '112'" @click="$emit('click', ['categoryId', '112'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/cy_10.png" alt="教练员">
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '113'" @click="$emit('click', ['categoryId', '113'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/cy_13.png" alt="危险品">
			</template>
		</van-grid-item>
		<van-grid-item :dot="subject.categoryId === '114'" @click="$emit('click', ['categoryId', '114'])">
			<template #icon>
				<img height="84" src="@/assets/vip-home/cy_14.png" alt="网约车">
			</template>
		</van-grid-item>
	</van-grid>
</template>

<script>
export default {
    props: ['subject'],
};
</script>

<style scoped lang="less">
</style>
